import React from "react";
import {motion} from "framer-motion"
import styled from "styled-components"
import Container from '../components/styled/Container'
import Row from '../components/styled/Row'
import Col from '../components/styled/Col'
import { NAV_HEIGHT } from "../components/Nav";
import {animateChildren, inAndUp, fadeIn, extraLong} from '../styles/animation'
import space from '../styles/space'
import breakpoints from '../styles/breakpoints'
import Portrait from '../components/Portrait'
import {Link} from 'gatsby'

const FancyLink = styled(motion.h1).attrs({
  variants: inAndUp,
  whileHover: {scale: 1.1},
  whileTap: {scale: 1}
})`
  cursor: pointer;
`

const FancyLinkWrapper = styled(Link)`
  display: block;
    
  :not(:first-child) {
    margin-top: ${space.xl}px;
  }
`

const FancyLinkContainer = styled(motion.div)`
  margin-bottom: ${space.xl}px;

  ${breakpoints.md} {
    margin-bottom: 0px;
  }
`

const StyledRow = styled(Row)`
  margin-top: ${space.md}px;

  ${breakpoints.md} {
    margin-top: ${space.xl}px;
  }

  ${breakpoints.lg} {
    margin-top: ${space.xxl}px;
  }
`

const IndexPage = () => (
  <Container>
    <StyledRow center responsive align='center'>
      <Col>
        <Portrait />
      </Col>
      <Col>
        <FancyLinkContainer variants={animateChildren} initial="hidden" animate="visible">
          <FancyLinkWrapper to='/hire-me'>
            <FancyLink>Freelance Developer</FancyLink>
          </FancyLinkWrapper>
          <FancyLinkWrapper to='/workshops'>
            <FancyLink>React Expert™</FancyLink>
          </FancyLinkWrapper>
          <FancyLinkWrapper to='/photography'>
            <FancyLink>Amateur Photographer</FancyLink>
          </FancyLinkWrapper>
        </FancyLinkContainer>
      </Col>
    </StyledRow>
  </Container>
);

export default IndexPage;
